import React from "react";
import "./App.scss";
import Nav from "../nav/Nav";
import Jumbotron from "../jumbotron/Jumbotron";
import {Info, InfoNoIcon} from "../info/Info";
import {Slider} from "../slider/Slider";
import {faAward, faBurn, faFlask, faIndustry, faTruckMoving} from "@fortawesome/free-solid-svg-icons";
import sliderImage1 from "../../assets/images/image1-min.jpg";
import sliderImage2 from "../../assets/images/image2-min.jpg";
import sliderImage3 from "../../assets/images/image3-min.jpg";
import {Footer} from "../footer/Footer";
import {NotFound} from "../notFound/NotFound";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <div className="App">
                        <Nav/>
                        <Jumbotron/>
                        <Info title={"4 ПРИЧИНЫ ВЫБРАТЬ ПОСТАВКУ\nСЖИЖЕННОГО ГАЗА\nОТ КОМПАНИИ ГАЗОЙЛ:"}
                              infoCards={[
                                  {
                                      title: "КРУПНЕЙШИЙ ПОСТАВЩИК СЖИЖЕННОГО УГЛЕВОДОРОДНОГО ГАЗА (СУГ) В СФО",
                                      description: "",
                                      icon: faFlask
                                  },
                                  {
                                      title: "СОБСТВЕННЫЙ АВТОПАРК ГАЗОВОЗОВ (БОЛЕЕ 80 МАШИН)",
                                      description: "",
                                      icon: faTruckMoving
                                  },
                                  { title: "ГАРАНТИЯ КАЧЕСТВА ГАЗА (ГОСТЫ)", description: "", icon: faAward },
                                  { title: "4 ГАЗОНАПОЛНИТЕЛЬНЫХ СТАНЦИИ (ГНС)", description: "", icon: faIndustry }
                              ]}/>
                        <Slider images={[
                            sliderImage1,
                            sliderImage2,
                            sliderImage3
                        ]}/>
                        <Info title={"НАШИ ПРЕИМУЩЕСТВА:"}
                              infoCards={[
                                  {
                                      title: "ГАЗ ОТ ПРОИЗВОДИТЕЛЯ",
                                      description: "Сжиженный емкостной газ напрямую от производителя",
                                      icon: faBurn
                                  },
                                  {
                                      title: "УДОВЛЕТВОРЕНИЕ ПОТРЕБНОСТЕЙ КЛИЕНТОВ",
                                      description: "Любой тип газа под ваши потребности",
                                      icon: faBurn
                                  },
                                  {
                                      title: "КВАЛИФИЦИРОВАННЫЕ УСЛУГИ",
                                      description: "Заправка газгольдеров сжиженным газом осуществляется сертифицированными специалистами",
                                      icon: faBurn
                                  },
                                  {
                                      title: "КАЧЕСТВЕННЫЙ ГАЗ",
                                      description: "Паспорт качества на каждую партию поставки",
                                      icon: faBurn
                                  },
                                  {
                                      title: "ВСЕГДА В НАЛИЧИИ",
                                      description: "Стабильное наличие газа",
                                      icon: faBurn
                                  },
                                  {
                                      title: "ДЛЯ ВСЕХ КАТЕГОРИЙ КЛИЕНТОВ",
                                      description: "Работаем как с юридическими, так и с физическими лицами",
                                      icon: faBurn
                                  }
                              ]}/>
                        <InfoNoIcon
                            title={"ЗАКЛЮЧИВ ДОГОВОР НА РЕГУЛЯРНОЕ ОБСЛУЖИВАНИЕ С КОМПАНИЕЙ ГАЗОЙЛ\nВЫ ПЕРЕСТАНЕТЕ БЕСПОКОИТЬСЯ\nО ЗАПАСЕ ГАЗА В ГАЗГОЛЬДЕРЕ:"}
                            titleCards={[
                                "ДОСТАВКА ОТ\n1-ГО ДНЯ",
                                "МИНИМАЛЬНАЯ ПАРТИЯ\nОТ 500 КГ",
                                "СОПРОВОЖДЕНИЕ\nМЕНЕДЖЕРА",
                                "ДОКУМЕНТЫ. ВОЗВРАТ НДС.\nУДОБНЫЙ РАСЧЁТ"
                            ]}/>
                        <Footer/>
                    </div>
                </Route>
                <Route path="*">
                    <NotFound/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
