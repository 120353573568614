import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export type InfoProps = {
    title: string,
    infoCards: Array<InfoCard>
}

export type InfoPropsNoIcon = {
    title: string,
    titleCards: Array<string>
}

export type InfoCard = {
    title: string,
    description: string,
    icon: IconProp
}

export const Info = (props: InfoProps) => {
    return (
            <article className="Info">
                <h2><span>{props.title}</span></h2>
                <div className="info__wrapper">
                    <div className="info__wrapper__grid">
                        {props.infoCards.map((el, i) => <div className="info__card" key={i}>
                            <FontAwesomeIcon icon={el.icon}/>
                            <h5 className={el.description !== "" ? "" : "only__title"}>{el.title}</h5>
                            {el.description !== "" ? <p>{el.description}</p> : ""}
                        </div>)}
                    </div>
                </div>
            </article>
    )
}

export const InfoNoIcon = (props: InfoPropsNoIcon) => {
    return (
            <article className="Info">
                <h2 className="info__header__extend">{props.title}</h2>
                <div className="info__wrapper">
                    <div className="info__wrapper__grid">
                        {props.titleCards.map((title, i) => <div className="info__card__extend" key={i}>
                            <h4><span>{title}</span></h4>
                        </div>)}
                    </div>
                </div>
            </article>
    )
}
