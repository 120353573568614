import logoFull from "../../assets/images/logo_full.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMobileAlt} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Nav = () => {
    return <nav>
        <div className="Logo">
            <a href="/"><img src={logoFull} alt={logoFull}/></a>
        </div>
        <div className="Contacts">
            <h2>ВСЕ РЕГИОНЫ, ОБЩИЕ ВОПРОСЫ</h2>
            <a href="tel://+79039100000"><FontAwesomeIcon className="FaIcon" icon={faMobileAlt}/> +7 (903)
                910-00-00</a>
        </div>
    </nav>
}

export default Nav
