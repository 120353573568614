// noinspection DuplicatedCode

import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowUp,
    faCircleNotch,
    faMailBulk,
    faMapMarkedAlt,
    faPhoneAlt,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import validator from "validator";
import axios from "axios";
import {encodeURI} from "js-base64";

const TEST = true;

type CloseHandler = () => void;

type PropsAlert = {
    condition: number,
    closeHandler: CloseHandler
}

const Alert = (props: PropsAlert) => {
    let text = "";
    let title = "";
    let className = "alert";

    switch (props.condition) {
        case 0:
            text = "-";
            title = "-";
            break;
        case 1:
            text = "Заявка успешно создана";
            title = "Успех!";
            className = "alert success";
            break;
        case 2:
            text = "Заполните все поля";
            title = "Внимание!";
            className = "alert error";
            break;
        case 3:
            text = "Проверка капчи не пройдена";
            title = "Внимание!";
            className = "alert error";
            break;
        case 4:
            text = "При отправке email сообщения произошла ошибка";
            title = "Внимание!";
            className = "alert error";
            break;
    }

    return (
        <div className={className}>
            <button onClick={props.closeHandler}><FontAwesomeIcon icon={faTimes}/></button>
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    );
};

export const Footer = () => {

    const [fio, setFio] = useState(false);
    const [inn, setInn] = useState(false);
    const [phone, setPhone] = useState(false);
    const [address, setAddress] = useState(false);
    const [value, setValue] = useState(false);
    const [email, setEmail] = useState(false);
    const [showAlert, setShowAlert] = useState(0);
    let condition = fio && inn && phone && address && value && email;

    const emptyValidationHandler = (element: HTMLInputElement) => {
        if (element.value === "") {
            element.classList.remove("success");
            element.classList.add("error");
        } else {
            element.classList.remove("error");
            element.classList.add("success");
        }

        switch (element.id) {
            case "name":
                setFio(element.value !== "");
                break;
            case "inn":
                setInn(element.value !== "");
                break;
            case "address":
                setAddress(element.value !== "");
                break;
            case "value":
                setValue(element.value !== "");
                break;
        }

        condition = fio && inn && phone && address && value && email;
    };

    const phoneValidationHandler = (element: HTMLInputElement) => {
        if (element.value === "") {
            element.classList.remove("success");
            element.classList.add("error");
            setPhone(false);
        } else {
            if (validator.isMobilePhone(element.value, "ru-RU")) {
                element.classList.remove("error");
                element.classList.add("success");
                setPhone(true);
            } else {
                element.classList.remove("success");
                element.classList.add("error");
                setPhone(false);
            }
        }

        condition = fio && inn && phone && address && value && email;
    };

    const emailValidationHandler = (element: HTMLInputElement) => {
        if (element.value === "") {
            element.classList.remove("success");
            element.classList.add("error");
            setEmail(false);
        } else {
            if (validator.isEmail(element.value)) {
                element.classList.remove("error");
                element.classList.add("success");
                setEmail(true);
            } else {
                element.classList.remove("success");
                element.classList.add("error");
                setEmail(false);
            }
        }

        condition = fio && inn && phone && address && value && email;
    };

    const sendClickValidator = async () => {
        if (condition) {
            axios.defaults.baseURL = "https://gk-gazoil.ru/exchange/feedback/gazoil_opt.php";

            (document.getElementById("button__loader__icon") as HTMLElement)
                .classList
                .add("loading");

            const nameB64 = encodeURI((document.getElementById("name") as HTMLInputElement).value);
            const innB64 = encodeURI((document.getElementById("inn") as HTMLInputElement).value);
            const phoneB64 = encodeURI((document.getElementById("phone") as HTMLInputElement).value);
            const addressB64 = encodeURI((document.getElementById("address") as HTMLInputElement).value);
            const valueB64 = encodeURI((document.getElementById("value") as HTMLInputElement).value);
            const emailB64 = encodeURI((document.getElementById("email") as HTMLInputElement).value);

            await axios.post(axios.defaults.baseURL, {
                name: nameB64,
                inn: innB64,
                phone: phoneB64,
                address: addressB64,
                value: valueB64,
                email: emailB64,
            }).then(response => {

                console.log(TEST ? "Have response" : "Don't have response");
                console.log(TEST ? response : "");

                if (response !== null && response !== undefined) {
                    switch (response.status) {
                        case 200:
                            setShowAlert(1);
                            break;
                        case 403:
                        case 400:
                        case 500:
                            setShowAlert(4);
                                break;
                        }
                        (document.getElementById("button__loader__icon") as HTMLElement)
                            .classList
                            .remove("loading");
                    } else {
                        console.log(TEST ? "Response is null or undefined" : "Empty response");
                    }
                }).catch(function (error) {
                    console.log(error.message);
                });
        } else {
            setShowAlert(2);
        }
    };

    const closeAlertHandler = () => {
        setShowAlert(0);
    };

    const toTopClickHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <footer>
            <div className="footer__wrapper">
                <div className="footer__content">
                    <div>
                        <h4>Оставить заявку</h4>
                        <div>
                            <label htmlFor="name">ФИО</label>
                            <input onChange={e => emptyValidationHandler(e.target)}
                                   onBlur={e => emptyValidationHandler(e.target)} id="name" type="text"
                                   placeholder="Введите ФИО"/>
                            <label htmlFor="inn">ИНН</label>
                            <input onChange={e => emptyValidationHandler(e.target)}
                                   onBlur={e => emptyValidationHandler(e.target)} id="inn" type="number"
                                   placeholder="Введите ИНН"/>
                            <label htmlFor="phone">Телефон</label>
                            <input onChange={e => phoneValidationHandler(e.target)}
                                   onBlur={e => phoneValidationHandler(e.target)} id="phone" type="number"
                                   placeholder="Введите телефон"/>
                            <label htmlFor="address">Адрес</label>
                            <input onChange={e => emptyValidationHandler(e.target)}
                                   onBlur={e => emptyValidationHandler(e.target)} id="address" type="text"
                                   placeholder="Введите адрес"/>
                            <label htmlFor="value">Обьем</label>
                            <input onChange={e => emptyValidationHandler(e.target)}
                                   onBlur={e => emptyValidationHandler(e.target)} id="value" type="number"
                                   placeholder="Введите объем"/>
                            <label htmlFor="email">E-mail</label>
                            <input onChange={e => emailValidationHandler(e.target)}
                                   onBlur={e => emailValidationHandler(e.target)} id="email" type="email"
                                   placeholder="Введите email"/>
                            <button onClick={sendClickValidator}>
                                <FontAwesomeIcon id="button__loader__icon" icon={faCircleNotch} size="lg"/> Отправить
                            </button>
                        </div>
                    </div>
                    <div>
                        <h4>Позвоните или оставьте заявку</h4>
                        <p>Наш менеджер свяжется с вами и сформирует для вас выгодное индивидуальное
                            предложение.</p>
                        <p>* Стоимость емкостного газа зависит от объема поставки, типа газа и расстояния до вашего
                            объекта.</p>
                        <h5>Наши контакты</h5>
                        <ul>
                            
                            <li><FontAwesomeIcon icon={faPhoneAlt}/> <a href="tel://+79069077969">+7 (906)
                                907-79-69 Новосибирская область</a></li>
                                <li><FontAwesomeIcon icon={faPhoneAlt}/> <a href="tel://+79628351220">+7 (962)
                                835-12-20 Алтайский край</a></li>
                                <li><FontAwesomeIcon icon={faPhoneAlt}/> <a href="tel://+79230001454">+7 (923)
                                000-14-54 Красноярский край, Республика Хакасия, Томская и Кемеровская область</a></li>
                            <li><FontAwesomeIcon icon={faMailBulk}/> <a
                                href="mailto://opt@sibgaz.ru">opt@sibgaz.ru</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="to_top__arrow" onClick={toTopClickHandler}>
                <FontAwesomeIcon icon={faArrowUp} size="lg"/>
            </div>
            {showAlert ? <Alert closeHandler={closeAlertHandler} condition={showAlert}/> : ""}
        </footer>
    );
};
