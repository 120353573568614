import React from "react";

const list = [
    "ПБТ (пропан-бутан технический)",
    "ПБА (пропан бутан автомобильный)",
    "ПА (пропан автомобильный)",
    "ПТ (пропан технический)",
    "БТ (бутан технический)",
]

const toFooterSection = () => {
    document.getElementsByTagName('footer')[0]
            .scrollIntoView({block: "center", behavior: "smooth"});
}

const Jumbotron = () => {
    return <article className="Jumbotron">
        <div className="wrapper__background">
            <div className="wrapper__content">
                <h2>ЗАПРАВКА<br/>ГАЗГОЛЬДЕРОВ</h2>
                <p>Поставки сжиженного емкостного газа<br/>(смесь пропан-бутана)</p>
                <ul>{list.map((el, i) => <li key={i}>{el}</li>)}</ul>
                <button onClick={toFooterSection} className="do__request">Оформить заявку на доставку</button>
            </div>
        </div>
    </article>
}

export default Jumbotron
