import React, {useState} from "react";

export type SliderProps = {
    images: Array<string>
}

export const Slider = (props: SliderProps) => {
    const [lightboxBackground, setLightboxBackground] = useState(false)
    const [currentLightboxImage, setCurrentLightboxImage] = useState(props.images[0])

    const handleClick = (img: string = '') => {
        setLightboxBackground(!lightboxBackground)
        if (img !== '') {
            setCurrentLightboxImage(img)
        }
    }

    return (
            <article className="Slider">
                {lightboxBackground ?
                        <div className="slider__lightbox__wrapper">
                            <img className="slider__lightbox__image" onClick={() => handleClick()}
                                 src={currentLightboxImage} alt={currentLightboxImage}/>
                        </div> : ''}
                {props.images.map((image, i) =>
                        <div className={`lightbox`} key={i}>
                            <img onClick={() => handleClick(image)} src={image} alt={image}/>
                        </div>)}
            </article>
    )
}
